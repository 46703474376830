import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$cols}, 1fr);

    row-gap: ${props => (props.$rowGap ? props.$rowGap : props.$gap)};
    column-gap: ${props => (props.$colGap ? props.$colGap : props.$gap)};
    place-items: ${props => (props.$placeItems ? props.$placeItems : 'normal')};

    >* {
        place-self: stretch;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
	div>img{
		max-width: 42px;
		max-height: 42px;
        min-width: 42px;
        min-height: 42px;
        object-fit: cover;
        aspect-ratio: 16/9;
        border-radius: 50%;
	}
`;

export {
	UserCounterTableRowsFooter,
	UserCounterTitleTable,
	UserCheckBox,
	UserTopContainerInfo,
	UserTableTHSpan,
	UserTableSpan,
	UserTableUserInfo,
	UserTitle,
	UserTableMainWrapper,
	StyledTableRow,
	UserPageWrapperElements,
	UserPageContainer
} from '../Users/Users.style';