import React from 'react';
import PropTypes from 'prop-types';
import {BillingRender, NotificationsRender, PersonalInfoRender, TeamRender} from './Tabs';
import {
	DropdownArrowIcon,
	FavoriteIcon,
	PencilIcon,
	UserIcon
} from '../../../icons';

export const UserInfoRender = ({
	menu2,
	selectedTab,
	setSelectedTab,
	userData,
	handleChange,
	loading,
	error
}) => {
	if (loading) return <p>Loading....</p>;
	if (error) return <p>Error</p>;
	if (!userData) return <p>No data available</p>;

	const iconComponents = {
		PencilIcon: <PencilIcon isActive={selectedTab === 'personal-information'} />,
		UserIcon: <UserIcon isActive={selectedTab === 'schools'} />,
	};

	const renderComponents = {
		PersonalInfoRender: <PersonalInfoRender userData={userData} handleChange={handleChange} />,
		TeamRender: <TeamRender userData={userData} />
	};

	return (
		<div className={'flex w-full h-full mt-2'}>
			<div className={'grid gap-3 max-w-xs col-span-2'} style={{maxWidth: '250px'}}>
				<div>
					<h2 className={'font-sans f-500 text-white'} style={{fontSize: '1rem'}}>Credentials</h2>
					{menu2.map(({ to, name, icon, component }) => (
						<div
							key={to}
							className={`flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark ${selectedTab === to ? 'font-bold active-nav-item-font-color' : ''}`}
							onClick={() => setSelectedTab(to)}
						>
							{iconComponents[icon]}
							<span className='ml-2'>{name}</span>
							<DropdownArrowIcon className='ml-auto' />
						</div>
					))}
				</div>
			</div>
			<div className='w-full tab-content'>
				{renderComponents[menu2.find(menu => menu.to === selectedTab)?.component]}
			</div>
		</div>
	);
};

UserInfoRender.propTypes = {
	menu2: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
			component: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedTab: PropTypes.string.isRequired,
	setSelectedTab: PropTypes.func.isRequired,
	userData: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
		profilePicture: PropTypes.shape({
			url: PropTypes.string,
		}),
		phone: PropTypes.string,
		position: PropTypes.string,
		location: PropTypes.string,
		website: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};