import styled from 'styled-components';

export const CoursesWrapperElementsAddOne = styled.div`
    width: 243px;
    height: 80px;
    border: 1px solid rgba(52, 59, 79, 1);
	border-radius: 8px;
	background-color: rgba(11, 23, 57, 1);

    span:first-child{
        font-size: 1rem;
        color: rgba(255, 255, 255, 1);
    }
	
	div:last-child>div{
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: rgba(203, 60, 255, 0.5);
		span{
            font-size: 2rem;
		}
	}
    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const CoursesCheckBox = styled.div`
	width: 12px;
	height: 12px;
	border: 1px solid rgba(126, 137, 172, 1);
	border-radius: 2px;
	background-color: rgba(203, 60, 255, 1);
`;


export const StyledTableRow = styled.tr`
    width: 100%;
    max-width: 1600px;
    height: 100%;
    border-radius: 0.0375rem;
    background-color: ${(props) =>
		props.index % 2 === 0 ? 'rgba(10, 19, 48, 1)' : 'rgba(11, 23, 57, 1)'};

    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const Separator = styled.div`
	width: 100%;
	height: 1px;
	border: 1px solid rgba(52, 59, 79, 1);
`;

export const CoursesTableMainWrapper = styled.table`
    border-collapse: collapse;
	box-decoration-break: unset;
	th, tr, th , tbody, td 	{
		border: transparent;
	}
`;

export const CoursesTopContainerInfo = styled.div`
	width: 243px;
	height: 80px;
    border: 1px solid rgba(52, 59, 79, 1);
	border-radius: 8px;
	background-color: rgba(11, 23, 57, 1);

	div:first-child{
		div:first-child{
            width: 40px;
            height: 40px;
            border-radius: 80px;
            background-color: ${(props) => props.bgColor || 'rgba(203, 60, 255, 0.5)'};
		}
	}
	
	
	span:first-child{
		font-size: 0.725rem;
        color: rgba(255, 255, 255, 1);
	}
	
	span:last-child{
		font-size: 0.725rem;
        color: rgba(174, 185, 225, 1);
    }

    &:hover {
        background: rgb(18, 29, 61)
    }
`;