import gql from 'graphql-tag';

const queries={
	questionsFindMany:gql`query questionsFindMany($filter: FilterFindManyquestionsInput, $limit: Int, $skip: Int){
	  QuestionsFindMany(filter:$filter sort: _ID_ASC, limit: $limit, skip: $skip) {
		_id
		label
        identifier
		responses
		rightAnswers
		createdAt
    	updatedAt
	  }
	}`,
	questionsFindOne:gql`query QuestionsFindOne($id:String!){
	  QuestionsFindOne(filter:{_id:$id},sort: _ID_ASC) {
		_id
		label
		identifier
		responses
		rightAnswers
		responsesObj{
		  _id
		  label
		  identifier
		  updatedAt
		  createdAt
		}
		rightAnswersObj{
		  _id
		  label
		  identifier
		  updatedAt
		  createdAt
		}
		language {
		  spanish{
			description
			title
			identifier
			label
		  }
		  english{
			description
			title
			identifier
			label
		  }
		  french{
			description
			title
			identifier
			label
		  }
		  _id
		}
		updatedAt
		createdAt
	  }
	}`,
	responsesFindMany:gql`
	query ResponsesFindMany($filter:FilterFindManyresponsesInput){
	  ResponsesFindMany(filter:$filter,limit: 1000){
		_id
		label
		identifier
		updatedAt
		createdAt
	  }
	}`,
	responsesFindOne:gql`
	query ResponsesFindOne($id:String!){
	  ResponsesFindOne(filter: {_id:$id}) {
		_id
		label
		language {
		  spanish{
			description
			title
			identifier
			label
		  }
		  english{
			description
			title
			identifier
			label
		  }
		  french{
			description
			title
			identifier
			label
		  }
		  _id
		}
		identifier
		updatedAt
		createdAt
	  }
	}`,
	classesAdminFindMany:gql`
	query ClassesAdminFindMany{
	  ClassesAdminFindMany(sort: ORDER_ASC) {
		_id
		trailer
		title
		description
		cover {
		  url
		  type
		}
		curriculum
		free
		extra
		deleted
		order
	  }
	}`,
	classesAdminFindOne:gql`
	query ClassesAdminFindOne($id:String!){
	  ClassesAdminFindOne(filter:{_id:$id}) {
          _id
          students
          trailer
          title
          description
          cover {
              url
              type
          }
          curriculum
          categories
          skills
          questions {
              question
              points
          }
          videos {
              url
              thumbnail{
                  url
                  type
              }
              type
          }
          tags
          extraQuestions {
              question
              points
          }
          subcategories
          slug
          free
          extra
          deleted
          order
          extracurricular {
              available
              points
          }
          documents {
              title
              url
              type
          }
          language {
              spanish{
                  title
                  description
                  identifier
                  label
              }
              english{
                  title
                  description
                  identifier
                  label
              }
              french{
                  title
                  description
                  identifier
                  label
              }
              _id
          }
          learningGoal
          episodeTime
          quizTime
          journalTime
          classActivityTime
          tapLibraryTime
          extendTime
          lessonLength
          overview
          extraActivities
          resources {
              _id
              url
              type
              title
          }
          activity {
              title
              description
          }
          discussion {
              title
              description
          }
          extend {
              title
              description
          }
          reflection {
              title
              description
          }
          bigIdea
          lifeSkill
          theme
          skillSet
          createdAt
          updatedAt
	  }
	}`,
	UsersFindMany: gql`
    query UsersFindMany($filter: FilterFindManyusersInput, $limit: Int, $skip: Int, $sort: SortFindManyusersInput!) {
        UsersFindMany(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
            _id
            firstName
            lastName
            userName
            tag
            bio
            birthday
            email
            profilePicture {
                type
                url
                title
            }
            points
            type
            lastLogin
            organization
            cleverId
            classLinkId
            cleverSync
            classLinkSync
            deedlyAuthenticationToken
            deedlyUserId
            createdAt
            updatedAt
        }
    }
`,
	usersFindOne:gql`
	query UsersFindOne($id:String!){
		UsersFindOne(_id:$id){
    		_id
    		firstName
    		lastName
    		userName
    		tag
    		bio
    		email
    		profilePicture {
      			url
      			type
    		}
    		type
    		lastLogin
    		updatedAt
    		createdAt
  		}
	}`,
	userTypesFindMany:gql`
	query UserTypesFindMany($filter: FilterFindManyusertypesInput, $sort: SortFindManyusertypesInput){
		UserTypesFindMany(filter: $filter, sort:$sort){
			_id
            identifier
            label
  		}
	}`,
	/*Life Skills Queries:*/
	lifeSkillFindOne:gql`
        query LifeSkillFindOne($id: String!) {
            LifeSkillFindOne(filter: { _id: $id }) {
                _id
                label
                identifier
                icon {
                    url
                    type
                }
                description
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	lifeSkillsFindMany:gql`
        query LifeSkillFindMany($filter: FilterFindManylifeskillInput, $skip: Int, $limit: Int, $sort: SortFindManylifeskillInput) {
            LifeSkillFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                label
                identifier
                icon {
                    url
                    type
                }
                description
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	/* Themes Queries */
	ThemeFindMany: gql`
    query ThemeFindMany($filter: FilterFindManythemeInput, $skip: Int, $limit: Int, $sort: SortFindManythemeInput) {
      ThemeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
        _id
        label
        identifier
        icon {
          url
          type
        }
        description
        deletedAt
        createdAt
        updatedAt
      }
    }
  `,
	themeFindOne: gql`
        query ThemeFindOne($id: String!) {
            ThemeFindOne(filter: { _id: $id }) {
                _id
                label
                identifier
                icon {
                    url
                    type
                }
                description
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	/* Skill Set Queries */
	skillSetFindMany: gql`
        query SkillSetFindMany($filter: FilterFindManyskillsetInput, $skip: Int, $limit: Int, $sort: SortFindManyskillsetInput) {
            SkillSetFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                label
                identifier
                icon {
                    url
                    type
                }
                description
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	skillSetFindOne: gql`
		query SkillSetFindOne($filter: FilterFindOneskillsetInput, $skip: Int, $sort: SortFindOneskillsetInput){
			SkillSetFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                label
                identifier
                icon {
                    url
                    type
                }
                description
                deletedAt
                createdAt
                updatedAt
			}
		}
	`,
	/* Courses Queries (curriculums) */
	curriculumsFindMany: gql`
        query CurriculumsFindMany($filter: FilterFindManycurriculumsInput, $skip: Int, $limit: Int, $sort: SortFindManycurriculumsInput) {
            CurriculumsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                title
                description
                hidden
                slug
                teacher
                animalCategory
                documents {
                    title
                    url
                    type
                }
                language {
                    spanish {
                        title
                        description
                        identifier
                        label
                    }
                    english {
                        title
                        description
                        identifier
                        label
                    }
                    french {
                        title
                        description
                        identifier
                        label
                    }
                    _id
                }
                leaderBio
                active
                cover {
                    url
                    type
                }
                loop {
                    url
                    type
                }
                logo {
                    url
                    type
                }
                trailer {
                    url
                    type
                }
                learningGoal
                lifeSkill
                skillSet
                theme
                resources {
                    _id
                    url
                    type
                    title
                }
                createdAt
                updatedAt
            }
        }
	`,
	CurriculumsFindOne: gql`
        query CurriculumsFindOne($id: String!) {
            CurriculumsFindOne(filter: { _id: $id }) {
                _id
                title
                description
                hidden
                slug
                teacher
                animalCategory
                documents {
                    title
                    url
                    type
                }
                language {
                    spanish {
                        title
                        description
                        identifier
                        label
                    }
                    english {
                        title
                        description
                        identifier
                        label
                    }
                    french {
                        title
                        description
                        identifier
                        label
                    }
                    _id
                }
                leaderBio
                active
                cover {
                    url
                    type
                }
                loop {
                    url
                    type
                }
                logo {
                    url
                    type
                }
                trailer {
                    url
                    type
					captions {
						language
						file {
							url
						}
						available
					}
                }
                learningGoal
                lifeSkill
                skillSet
                theme
                resources {
                    _id
                    url
                    type
                    title
                }
                createdAt
                updatedAt
            }
        }
	`
	,
	//Mood Queries:
	MoodFindMany: gql`
        query MoodFindMany($filter: FilterFindManymoodInput, $skip: Int, $limit: Int, $sort: SortFindManymoodInput){
            MoodFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                label
                identifier
                cover {
                    url
                    type
                }
                video {
                    url
                    type
                }
                description
                order
				color
                tips
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	MoodFindOne: gql`
        query MoodFindOne($filter: FilterFindOnemoodInput, $skip: Int, $sort: SortFindOnemoodInput){
            MoodFindOne(filter: $filter, skip: $skip, sort: $sort) {
                _id
                label
                identifier
                cover {
                    url
                    type
                }
                video {
                    url
                    type
                }
                description
                order
                color
                tips
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	//Avatar Queries:
	AvatarFindMany: gql`
		query AvatarFindMany($filter: FilterFindManyavatarInput, $limit: Int,$skip: Int, $sort: SortFindManyavatarInput){
         AvatarFindMany(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
            _id
            artwork {
                type
                url
            }
            createdAt
            curriculum
            identifier
            label
            price
            type
            createdAt
            updatedAt
            deletedAt
        }
    }`,
	//Collectibles Queries:
	CollectiblesFindMany: gql`query CollectiblesFindMany($filter: FilterFindManycollectibleInput, $skip: Int, $limit: Int, $sort: SortFindManycollectibleInput) {
        CollectiblesFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            _id
            title
            cover {
                url
                type
            }
            artwork {
                url
                type
            }
            description
            curriculum
            price
            editions
            video{
                url
                type
            }
            deletedAt
            createdAt
            updatedAt
        }
    }`,
	//Pin Queries:
	pinFindMany: gql` query PinFindMany($filter: FilterFindManypinInput, $skip: Int, $sort: SortFindManypinInput){
	PinFindMany(filter: $filter, skip: $skip, sort: $sort) {
			    _id
				label
				identifier
				classId: class
				sparkLibrary
				deletedAt
				cover {
				  url
				  type
				}
				video {
				  url
				 type
				}
				times
				createdAt
				updatedAt
		}
	}`,
	sparkLibraryFindMany: gql` query SparkLibraryFindMany($filter: FilterFindManysparklibraryInput, $skip: Int, $sort: SortFindManysparklibraryInput){
        sparkLibraryFindMany(filter:$filter, skip:$skip, sort:$sort){
            _id
            title
            description
            lesson
            curriculum
            type
            videoQuestions {
                question
                points
            }
            articleQuestion {
                question
                points
            }
            cover {
                url
                type
            }
            video {
                url
                thumbnail{
                    url
                    type
                }
                type
            }
            article {
                url
                type
            }
            journals {
                question
                points
            }
            mindfulMoment {
                question
                points
            }
            funFact {
                question
                points
            }
            slug
            deleted
            order
            times
            createdAt
            updatedAt
		}
	}`,
	districtFindMany: gql`
		query DistrictFindMany($filter: FilterFindManydistrictInput, $skip: Int, $sort: SortFindManydistrictInput){
			  DistrictFindMany(filter: $filter, skip: $skip, sort: $sort) {
			  	_id
				name
				state
				country
				organization
				createdAt
				updatedAt
			  }
		}
	`,
	districtFindOne: gql`
		query DistrictFindOne($filter: FilterFindOnedistrictInput, $skip: Int, $sort: SortFindOnedistrictInput){
			DistrictFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                name
                state
                country
                organization
                createdAt
                updatedAt
			}
		}
	`,
	stickerFindMany: gql`
	query StickerFindMany($filter: FilterFindManystickerInput, $skip: Int, $limit: Int, $sort: SortFindManystickerInput){
        StickerFindMany(filter: $filter, skip: $skip, sort: $sort, limit: $limit){
            _id
            label
            identifier
            deedlyVault
            deletedAt
            cover {
                type
                url
            }
            video {
                type
                url
            }
            createdAt
            updatedAt
		}
	}`,
	badgesFindMany: gql`
		query BadgesFindMany($filter: FilterFindManybadgeInput, $skip: Int, $limit: Int, $sort: SortFindManybadgeInput){
            BadgesFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                _id
                label
                identifier
                curriculum
                cover {
                    url
                    type
                }
                video {
                    url
                    type
                }
                deletedAt
                createdAt
                updatedAt
			}	
		}
	`,
	badgesFindOne: gql`
        query BadgesFindOne($filter: FilterFindOnebadgeInput, $skip: Int, $sort: SortFindOnebadgeInput){
            BadgesFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                label
                identifier
                curriculum
                cover {
                    url
                    type
                }
                video {
                    url
                    type
                }
                deletedAt
                createdAt
                updatedAt
            }
        }
	`,
	schoolFindMany: gql`
        query SchoolFindMany($filter: FilterFindManyschoolsDataInput, $skip: Int, $limit: Int, $sort: SortFindManyschoolsDataInput){
            SchoolFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                _id
				name
				state
				city
				district
				country
				slug
				deletedAt
				createdAt
				updatedAt
				settingsObj {
				  _id
				  accessWithEmail
				  accessWithClever
				  accessWithClassLink
				  accessWithGoogle
				  deleted
				  createdAt
				  updatedAt
				}
            }
        }
	`,
	schoolFindOne: gql`
        query SchoolFindOne($filter: FilterFindOneschoolsDataInput, $skip: Int, $sort: SortFindOneschoolsDataInput){
            SchoolFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
				name
				state
				city
				district
				country
				slug
				deletedAt
				createdAt
				updatedAt
				settingsObj {
				  _id
				  accessWithEmail
				  accessWithClever
				  accessWithClassLink
				  accessWithGoogle
				  deleted
				  createdAt
				  updatedAt
				}
            }
        }
	`,
	vaultFindMany: gql`
        query VaultFindMany($limit: Int, $skip: Int){
            VaultFindMany(limit: $limit, skip: $skip){
                charityVaults {
                    id
                    charity{
                        id
                        category{
                            id
                            label
                            color
                            logo
                        }
                        name
                        intro
                        logo
                        link
                        description
                    }
                    title
                    goalDeedcoin
                    goalUsd
                    cover
                    description
                    createdAt
            	}
			}
        }
	`,
	schoolUserFindOne: gql`
        query SchoolUserFindOne($filter:FilterFindOneschoolusersschoolInput, $skip: Int, $sort:SortFindOneschoolusersschoolInput){
            SchoolUserFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                user
                school
                pending
                deleted
                organization
                classLink
                createdAt
                updatedAt
                schoolObj {
                    _id
                    name
                    state
                    city
                    district
                    country
                    slug
                    deletedAt
                    createdAt
                    updatedAt
                    settingsObj{
                        _id
                        accessWithEmail
                        accessWithClassLink
                        accessWithGoogle
                        accessWithClever
                        deleted
                        createdAt
                        updatedAt
                    }
                }
            }
        }
	`,
};

export const query=(value)=>{
	return queries[value];
};
