import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {UserInfoRender} from './UserInfoRender';

export const UserInfoLoad = () => {
	const { id } = useParams();
	const [selectedTab, setSelectedTab] = useState('personal-information');
	const [userData, setUserData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		profilePicture: { url: '' },
		phone: '',
		position: '',
		location: '',
		website: ''
	});

	const { data, loading, error } = useQuery(query('UsersFindMany'), {
		variables: { filter: { _id: id }, sort: '_ID_DESC' },
	});

	useEffect(() => {
		const user = data?.UsersFindMany?.[0];
		if (user) {
			setUserData(user);
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserData({
			...userData,
			[name]: value,
		});
	};

	const menu2 = [
		{
			to: 'personal-information',
			name: 'Personal Information',
			icon: 'PencilIcon',
			component: 'PersonalInfoRender'
		},
		{
			to: 'schools',
			name: 'Schools',
			icon: 'UserIcon',
			component: 'TeamRender'
		}
	];

	return (
		<UserInfoRender
			menu2={menu2}
			selectedTab={selectedTab}
			setSelectedTab={setSelectedTab}
			userData={userData}
			handleChange={handleChange}
			loading={loading}
			error={error}
		/>
	);
};