import Input from '../../../../components/ui/Input';
import PropTypes from 'prop-types';
import {BriefcaseIcon, EmailIcon, ImageIcon, LocationIcon, UserIcon, WebsiteIcon} from '../../../../icons';

export const PersonalInfoRender = ({ userData, handleChange }) => {
	return (
		<div className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
			<h2 className={'font-sans mb-5'}>Personal Information</h2>
			<div className={'p-5 mb-12 rounded-lg'}
				 style={{border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)'}}>
				<div className={'flex justify-between align-center gap-4'}>
					<div className={'flex justify-center align-center'} style={{minWidth: '80px'}}>
						<div>
							<UserIcon width={'14px'} height={'14px'} background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-1'}>Full Name</span>
					</div>
					<Input
						className={'w-full'}
						label={'First Name'}
						type='text'
						name='First Name'
						value={userData.firstName}
						onChange={handleChange}
					/>
					<Input
						className={'w-full'}
						label={'Last Name'}
						type='text'
						name='Last Name'
						value={userData.lastName}
						onChange={handleChange}
					/>
				</div>
				<div className={'w-full mt-2 mb-2'} style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>
				<div className={'flex justify-between align-center gap-3'}>
					<div className={'flex justify-center align-center'}>
						<div>
							<EmailIcon background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-1'}>Email</span>
					</div>
					<Input
						className={'w-full'}
						label={''}
						type={'text'}
						name='email'
						value={userData.email}
						onChange={() => {
						}}
					/>
				</div>
				<div className={'w-full mt-2 mb-2'} style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>
				<div className='grid gap-5'>
					<div className='flex justify-start items-center'>
						<ImageIcon background={'rgba(255, 255, 255, 1)'}/>
						<span className='font-mono ml-1'>Photo</span>
					</div>
					<div className='grid place-items-center gap-4 p-5'>
						<div
							className='flex justify-center items-center cursor-pointer text-center py-5 rounded-full'
							style={{width: '3.5rem', height: '3.5rem', background: 'rgba(203, 60, 255, 0.5)'}}
						>
							<ImageIcon background={'rgba(203, 60, 255, 1)'}/>
						</div>
						<div className='text-center'>
							<h5 className='font-mono mt-4'>
								<b className='cursor-pointer'>Click to upload</b> or drag and drop
							</h5>
							<h5 className='font-mono'>SVG, PNG, JPG or GIF (max. 800 x 400px)</h5>
						</div>
					</div>
				</div>
			</div>
			<h2 className={'font-sans mb-5'}>Basic Information</h2>
			<div className={'p-5 rounded-lg'}
				 style={{background: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)'}}>
				<div className={'flex justify-between align-center'}>
					<div className={'flex justify-center align-center'}>
						<div>
							<UserIcon width={'14px'} height={'14px'} background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-2'}>Phone</span>
					</div>
					<Input
						className={'w-full ml-2'}
						label={''}
						type={'text'}
						name='phone'
						value={userData.phone || ''}
						onChange={() => {
						}}
					/>
				</div>
				<div className={'w-full mt-2 mb-2'} style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>
				<div className={'flex justify-between align-center'}>
					<div className={'flex justify-center align-center max-w-2xl'}>
						<div>
							<BriefcaseIcon background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-1'}>Position / Role</span>
					</div>
					<Input
						className={'w-full ml-2'}
						label={''}
						type={'text'}
						name='position / Role'
						value={userData.position || ''}
						onChange={() => {
						}}
					/>
				</div>
				<div className={'w-full mt-2 mb-2'} style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>
				<div className={'flex justify-between align-center'}>
					<div className={'flex justify-center align-center'}>
						<div>
							<LocationIcon background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-1'}>Location</span>
					</div>
					<Input
						className={'w-full ml-2'}
						label={''}
						type={'text'}
						name='location'
						value={userData.location || ''}
						onChange={() => {
						}}
					/>
				</div>
				<div className={'w-full mt-2 mb-2'} style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>
				<div className={'flex justify-between align-center'}>
					<div className={'flex justify-center align-center'}>
						<div>
							<WebsiteIcon background={'rgba(255, 255, 255, 1)'}/>
						</div>
						<span className={'font-mono ml-1'}>Website</span>
					</div>
					<Input
						className={'w-full ml-2'}
						label={''}
						type={'text'}
						name='website'
						value={userData.website || ''}
						onChange={() => {
						}}
					/>
				</div>
			</div>
		</div>
	);
};

PersonalInfoRender.propTypes = {
	userData: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
		profilePicture: PropTypes.shape({
			url: PropTypes.string,
		}),
		phone: PropTypes.string,
		position: PropTypes.string,
		location: PropTypes.string,
		website: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
};